<template>
  <div style="position: relative;width: 100%;height: 100%;">
    <div
      style="position: absolute;top:10px;bottom:10px;left:10px;right:10px;background: #515151;display: flex;align-items: center;justify-content: center">
      <img src="../../../assets/img/video/none.png" style="width: 124px;height: 124px" v-if="!display"/>
      <slot></slot>
    </div>
    <div class="source" v-if="display">
<!--      <span style="color: #ffffff;font-size: 14px;margin-right: 10px">福州二中</span>-->
      <span style="color: #B1CEF4;font-size: 14px;">{{ name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlayerContainer',
  props: {
    name: {
      type: String,
      default: '刘永涛'
    },

    display: {
      type: Boolean,
      default() {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.source {
  position: absolute;
  bottom: 10px;
  height: 38px;
  left: 10px;
  background: rgba(0, 0, 0, .5);
  line-height: 38px;
  padding: 0 12px
}
</style>
