export default {
  methods: {

    // 加载文本插件
    useTextRoom () {
      const _this = this
      this.conferencing.attachTextRoom({
        onDataOpen: function (handler) {
          handler.data({
            text: JSON.stringify({
              textroom: 'join',
              room: _this.roomId,
              transaction: _this.transaction,
              username: _this.transaction,
              // display: '游客' + _this.$tools.uuid().substr(0, 4)
              // display: _this.$route.query.name
              display: _this.nickName
            })
          })
        },
        onData: function (handler, data) {
          const json = JSON.parse(data)
          console.log(json)
          if (json.textroom === 'join') {
            _this.textRoomUserMapper[json.username] = json
            if (json.username !== _this.transaction) {
              _this.textRoomChatList.push({
                type: 'note',
                text: json.display + '进入会议室'
              })
              _this.memberList.push({
                name: json.display,
                id: json.username,
                audio: false,
                video: false
              })
            }
          } else if (json.textroom === 'success') {
            if (typeof json.participants !== 'undefined') {
              _this.memberList = []
              console.log(json.participants)
              json.participants.forEach(item => {
                _this.textRoomUserMapper[item.username] = item
                _this.memberList.push({
                  name: item.display,
                  id: item.username,
                  audio: false,
                  video: false
                })
              })
              _this.relationUser()
            }
          } else if (json.textroom === 'message') {
            const message = JSON.parse(json.text)
            if (message.type === 'chat') {
              _this.textRoomChatList.push({
                type: 'text',
                text: message.data,
                from: _this.textRoomUserMapper[json.from]
              })
            }
            console.log(_this.transaction)

            if (message.type === 'join' && message.data === _this.transaction) {
              if (_this.isJoined) {
                _this.shareScreen('media')
              } else {
                _this.joinVideoRoom(_this.conferencing.handler.videoRoom)
              }
            } else {
              // _this.stopPublishMedia()
            }

            if (message.type === 'stopPublish' && message.data === _this.transaction) {
              _this.stopPublishMedia()
            }
            if (message.type === 'joined' && message.id !== _this.videoRoomSessionId) {
              _this.subscribeVideo(message.id)
              console.log(_this.memberList)
              _this.memberList.forEach(item => item.video = false)
              const member = _this.memberList.find(item => item.id === json.from)
              console.log(member)
              member.video = true
            }
          } else if (json.textroom === 'leave') {
            console.log(json, _this.memberList, 'jsonjsonjsonjsonjsonjsonjson')
            const index = _this.memberList.findIndex(item => item.id === json.username)
            if (index > -1) _this.memberList.splice(index, 1)
            _this.textRoomChatList.push({
              type: 'note',
              text: _this.textRoomUserMapper[json.username].display + '离开会议室'
            })
          }
        }
      })
    },
    // 发送信息
    sendMessageToAll (data) {
      this.conferencing.handler.textRoom.data({
        text: JSON.stringify({
          textroom: 'message',
          transaction: this.transaction,
          room: this.roomId,
          text: JSON.stringify(data)
        }),
        error: function (reason) {
          this.$message.error(reason)
        }
      })
    },

    // 发送文本信息
    sendTextMessage (message) {
      this.sendMessageToAll({ type: 'chat', data: message })
    }
  }
}
