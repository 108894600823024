<template>
  <div style="flex: 1;display: flex;flex-direction: row;">
    <div class="operation" :class="{'deny': item.disabled}"
         v-for="(item,index) in buttons" :key="index" @click="onButtonClick(item)" v-show="item.display">
      <div style="height: 24px;"></div>
      <div style="margin: 0 auto;" class="icon">
        <img :src="'/static/images/icon/' + item.icon" class="icon"/>
      </div>
      <p class="text">{{ item.label }}</p>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      mediaSource: 'camera',
      isMuted: false,
      isMicrophoneMuted: false
    }
  },
  computed: {
    buttons () {
      return [
        {
          label: '共享屏幕',
          icon: 'share-white.png',
          code: 'screen',
          display: this.mediaSource === 'camera'
        },
        {
          label: '关闭共享',
          icon: 'share-deny.png',
          code: 'camera',
          display: this.mediaSource === 'screen'
        },
        {
          label: '打开摄像头',
          icon: 'camera.png',
          code: 'camera',
          display: this.mediaSource === 'screen' || this.isMuted
        },
        {
          label: '关闭摄像头',
          icon: 'camera-deny.png',
          code: 'camera',
          display: this.mediaSource === 'camera' && !this.isMuted
        },
        {
          label: '开启麦克风',
          icon: 'microphone-white-28.png',
          code: 'microphone',
          display: this.isMicrophoneMuted
        },
        {
          label: '关闭麦克风',
          icon: 'microphone-deny-28.png',
          code: 'microphone',
          display: !this.isMicrophoneMuted
        }
      ]
    }
  },

  methods: {
    onButtonClick (item) {
      if (item.code === 'camera' && this.mediaSource === 'camera') return this.toggleVideoMuted()
      if (item.code === 'microphone') return this.toggleMicrophoneMuted()
      this.mediaSource = item.code
      this.isMuted = false
      this.$emit('changeMediaSource', item.code)
    },

    toggleVideoMuted () {
      this.isMuted = !this.isMuted
      this.$emit('toggleVideoMuted')
    },

    toggleMicrophoneMuted () {
      this.isMicrophoneMuted = !this.isMicrophoneMuted
      this.$emit('toggleMicrophoneMuted')
    }
  }
}
</script>
<style scoped lang="scss">
.operation {
  width: 126px;
  height: 100px;
  &:hover {
    cursor: pointer;
    background: #0A1122;
  }
  &.deny {
    cursor: not-allowed;
  }
  .icon {
    width: 28px;
    height: 28px;
  }

  .text {
    font-size: 15px;
    color: #FFF;
    text-align: center;
    margin-top: 12px
  }
}

</style>
