<template>
  <div style="height: 308px;background: #19253A;overflow: auto">
    <template v-for="(member) in members">
      <div style="height: 42px;display: flex;flex-direction: row;align-items: center;padding: 0 12px"
           :key="member.id" v-if="member.name">
        <div style="width: 15px;height: 20px;background: #f2ad4b;margin-right: 12px"></div>
<!--        <span style="color: #ffffff;font-size: 14px">福州二中</span>-->
        <span style="color: #B1CEF4;font-size: 14px;margin-left: 10px">{{ member.name }}</span>
        <div style="flex: 1"></div>
        <div class="function" style="display: flex;flex-direction: row;">
          <div style="width: 20px;height: 20px;margin-left: 12px;cursor: pointer" v-for="(item, index) in buttonList"
               :key="index" @click="onButtonClick(member, item)">
            <img :src="'/static/images/icon/' + (member[item.code] ? item.icon:item.icon2)"
                 style="width:20px;height:20px"/>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    members: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      buttonList: [
        { icon: 'audio.png', code: 'audio', icon2: 'audio-deny.png' },
        { icon: 'video.png', code: 'video', icon2: 'video-deny.png' }
      ]
    }
  },

  methods: {

    onButtonClick (member, button) {
      console.log(button.code, member)
      this.$emit(button.code, member)
    }
  }
}
</script>
