<template>
  <div
    style="height: 44px;background: #374961;display: flex;flex-direction:row;align-items: center;padding: 0 12px">
    <span style="color: #FFFFFF;font-size: 14px">{{title}}</span>
    <div style="flex: 1"></div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
}
</script>
