export default {
  methods: {

    toggleVideoMuted () {
      const handler = this.conferencing.handler.videoRoom
      handler.isVideoMuted() ? handler.unmuteVideo() : handler.muteVideo()
    },

    changeBitrate (bitrate) {
      const handler = this.conferencing.handler.videoRoom
      handler.send({ message: { request: 'configure', bitrate } })
    },

    toggleMicrophoneMuted () {
      const handler = this.conferencing.handler.videoRoom
      handler.isAudioMuted() ? handler.unmuteAudio() : handler.muteAudio()
    },

    changeMemberAudio () {

    },

    changeMemberVideo (member) {
      if (!this.isOwner) return
      if (member.video) {
        member.video = false
        this.sendMessageToAll({ type: 'stopPublish', data: member.id })
      } else {
        const item = this.memberList.find(item => item.video)
        if (item !== undefined) {
          item.video = false
          this.sendMessageToAll({ type: 'stopPublish', data: item.id })
        }
        this.$confirm('您确定要让【' + member.name + '】上麦吗？', '提示').then(res => {
          this.sendMessageToAll({ type: 'join', data: member.id })
        })
      }
    },

    joinVideoRoom (handler) {
      handler.send({
        message: {
          request: 'join',
          room: this.roomId,
          id: Number(this.transaction),
          ptype: 'publisher',
          display: this.nickName // '用户' + this.$tools.uuid().substr(0, 5)
        }
      })
    },

    // 停止推送视频媒体
    stopPublishMedia (callback) {
      const _this = this
      _this.conferencing.handler.videoRoom.send({
        message: { request: 'unpublish' },
        success: function () {
          callback && callback()
        }
      })
    },

    createRemoteFeed (id, display, audio, video) {
      const _this = this
      let remoteFeed = null
      let bAppend = false
      console.log('ididididid', id)
      _this.conferencing.attachVideoRoom({
        mode: 'remote',

        onSuccess: function (handler) {
          remoteFeed = handler
          remoteFeed.simulcastStarted = false
          const subscribe = {
            request: 'join',
            room: _this.roomId,
            ptype: 'subscriber',
            feed: id
          }
          if (window.Janus.webRTCAdapter.browserDetails.browser === 'safari' &&
            (video === 'vp9' || (video === 'vp8' && !window.Janus.safariVp8))) {
            if (video) video = video.toUpperCase()
            subscribe.offer_video = false
          }
          remoteFeed.videoCodec = video
          remoteFeed.send({ message: subscribe })
        },

        onMessage: function (message, jsep) {
          const event = message.videoroom
          if (event === 'attached') {
            let index = _this.videoList.findIndex((item, idx) => item.feed && item.feed.rfid === id)
            if (index < 0) index = _this.videoList.findIndex((item, idx) => idx > 0 && !item.feed)
            // 临时处理
            if (index < 0) index = 0

            _this.videoList[index].feed = remoteFeed
            _this.videoList[index].uuid = message.id
            _this.videoList[index].name = message.display
            _this.videoList[index].video = remoteFeed.isVideoMuted()
            _this.videoList[index].audio = remoteFeed.isAudioMuted()
            remoteFeed.rfid = message.id
            remoteFeed.rfdisplay = message.display
            remoteFeed.rfindex = index
          }
          if (jsep) {
            remoteFeed.createAnswer({
              jsep,
              media: { audioSend: false, videoSend: false },
              success: function (jsep) {
                remoteFeed.send({ message: { request: 'start', room: _this.roomId }, jsep })
              }
            })
          }
        },

        onLeaving: function () {
          console.log('onLeaving')
        },

        onClearUp: function (handler, id) {
          const remoteFeed = _this.videoList.find(item => item.feed && item.feed.rfid === id)
          if (remoteFeed === undefined) return
          const videoItem = _this.videoList[remoteFeed.feed.rfindex]
          videoItem.display = false
          videoItem.video = false
          videoItem.audio = false
          // remoteFeed.feed.detach()
          videoItem.feed = null
          // _this.relationUser()
        },

        onRemoteStream: function (stream) {
          if (!bAppend) {
            bAppend = true
            window.Janus.attachMediaStream(_this.$refs.video[remoteFeed.rfindex], stream)
            _this.videoList[remoteFeed.rfindex].display = true
            _this.play(_this.videoList[remoteFeed.rfindex])
          }
        }
      })
    }
  }
}
