<template>
  <div style="display: flex;flex-direction: row;align-items: center">
    <div style="margin-right: 40px">
      <span style="font-size: 14px;color: #ABB0BF;">
        带宽速率：
      </span>
      <select
        style="width:130px;height:35px;background:#0E182F;border: 1px solid #414C65;border-radius:4px;color:#FFF;padding-left: 6px" v-model="bitrate" @click="changeBitrate">
        <option v-for="(item, index) in borderWidth" :key="index" :value="item">
          {{ item === 0 ? '不限制' : item + 'kbit' }}
        </option>
      </select>
    </div>

<!--    <div style="margin-right: 10px">-->
<!--      <img src="/static/images/icon/microphone-white.png" class="icon"/>-->
<!--    </div>-->
<!--    <volume-slider/>-->
<!--    <div style="width: 30px"></div>-->
<!--    <div style="margin-right: 10px">-->
<!--      <img src="/static/images/icon/audio-volume.png" class="icon"/>-->
<!--    </div>-->
<!--    <volume-slider/>-->
<!--    <div style="width: 30px"></div>-->
  </div>
</template>
<script>
// import VolumeSlider from './VolumeSlider'

export default {
  components: {
    // VolumeSlider
  },

  data () {
    return {
      sliderSize: 0,
      ratio: 0,
      borderWidth: [0, 128, 256, 512, 1024, 1500, 2000],
      bitrate: 0,
      lastBitrate: 0
    }
  },

  methods: {
    changeBitrate () {
      if (this.bitrate === this.lastBitrate) return
      this.lastBitrate = this.bitrate
      this.$emit('changeBitrate', this.bitrate * 1000)
    },

    onVolumeBarClick (event) {
      this.sliderSize = this.$refs.slider.clientWidth
      const sliderOffsetLeft = this.$refs.slider.getBoundingClientRect().left
      this.ratio = (event.clientX - sliderOffsetLeft) / this.sliderSize * 100
    }
  }
}
</script>
<style scoped lang="scss">
.icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
}
</style>
