<template>
  <div style="width: 100%;height: 100%;position:relative;">
    <div class="header">
      <div style="flex: 1">
        <span style="font-size:26px;">{{ conferencingName }}</span>
      </div>
<!--      <span style="font-size:18px;">张骆米</span>-->
      <img src="../../../assets/img/video/logout.png" style="width: 25px;height: 24px;margin-left: 11px;cursor: pointer" @click="logout"/>
    </div>

    <!-- 主区 -->
    <div style="position:absolute;top: 60px;bottom: 100px;left: 0;right: 380px;">
      <slot></slot>
    </div>

    <!-- 底部 -->
    <div style="position: absolute;bottom: 0;height: 100px;right: 380px;left: 0;background: #0E182F">
      <slot name="bottom"></slot>
    </div>

    <!-- 右边 -->
    <div style="position: absolute;right: 0;width: 380px;top: 60px;bottom: 0;background: #19253A">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      conferencingName: this.$route.query.conferencingName || ''
    }
  },

  methods: {
    logout() {
      // window.close()
      this.$confirm('确定要退出吗？', '提示', {
        type: 'warning'
      }).then(() => {
        this.$emit('onBeforeCloseWindow')
        setTimeout(() => {
          window.close()
        }, 30)
      }).catch(() => {
        console.log('error')
      })

    }
  }
}
</script>
<style scoped>
.header {
  height: 60px;
  font-weight: bold;
  color: #FFFFFF;
  background: #010D2F;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px
}
</style>
