<template>
  <div style="flex:1;background: #19253A;overflow: auto" ref="chatContainer">
    <div style="padding: 0 12px;margin: 10px 0;word-break:break-all">
    </div>
  <template v-for="(item, index) in chatList">
    <div style="padding: 0 12px;margin: 10px 0;word-break:break-all"  :key="index" >
      <p style="color: #53ACEB;font-size: 14px;line-height: 20px" v-if="item.type === 'note'">系统消息：{{item.text}}</p>
      <p style="color: #53ACEB;font-size: 14px;line-height: 20px" v-if="item.type === 'text'">
        <span style="color: #54DCA1;">{{item.from.display}}：</span>
        <span style="color: #B1CEF4;">{{item.text}}</span>
      </p>
    </div>
  </template>
<!--    <div style="padding: 0 12px;margin: 10px 0" v-for="index in 3" :key="index">-->
<!--      <span style="color: #FFCC00;font-size: 14px">主讲刘永涛：</span>-->
<!--      <span style="color: #B1CEF4;font-size: 14px;line-height: 20px">大家都能听见声音吗？没声音在这里说下下-->
<!--声音吗？没声音在这里说下</span>-->
<!--    </div>-->
  </div>
</template>
<script>
export default {
  props: {
    chatList: {
      type: Array,
      default () {
        return []
      }
    }
  },

  watch: {
    chatList () {
      setTimeout(() => {
        this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight
      }, 30)
    }
  }
}
</script>
