<template>
  <div style="padding-left: 8px;overflow-x: scroll;width: 380px;display: flex;flex-direction: row;height: 100px">
    <div class="file-item" :class="{'deny': item.disabled}"
         v-for="(item,index) in files" :key="index">
      <div style="margin: 0 auto;" class="icon">
        <img :src="'/static/images/file-types/' + item.icon" class="icon"/>
      </div>
      <p class="text">{{ item.name }}</p>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      mediaSource: 'camera',
      isMuted: false,
      files: [
        { name: '网站快速成型工具.ppt', icon: 'ppt.png' },
        { name: '网站快速成型工具.ppt', icon: 'ppt.png' },
        { name: '网站快速成型工具.ppt', icon: 'ppt.png' },
        { name: '网站快速成型工具.ppt', icon: 'ppt.png' }
      ]
    }
  },
  methods: {}
}
</script>
<style scope lang="scss">
.file-item {
  width: 70px;
  height: 84px;
  margin: 8px 0;
  flex-shrink: 0;
  padding-top: 12px;
  &:hover {
    cursor: pointer;
    background: #0A1122;
  }

  &.deny {
    cursor: not-allowed;
  }

  .icon {
    width: 30px;
    height: 30px;
  }

  .text {
    width: 50px;
    font-size: 12px;
    color: #FFF;
    text-align: center;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    margin: 6px auto 0;
    -webkit-box-orient: vertical;
  }
}

</style>
