<template>
  <div style="height: 62px;width: 100%;background: #131E32;display: flex;flex-direction: row;align-items: center">
    <div style="width: 14px"></div>
    <textarea class="talk" v-model="talk" @keydown="onKeyDown"></textarea>
    <div class="send-button" @click="sendMessage">
      发送
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      talk: ''
    }
  },

  methods: {
    onKeyDown (event) {
      if (event.keyCode === 13) {
        this.sendMessage()
        event.preventDefault()
        return false
      }
    },

    sendMessage () {
      if (!this.talk) return
      this.$emit('send', this.talk)
      this.talk = ''
    }
  }
}
</script>
<style scoped>
.talk {
  width: 100%;
  border: none;
  resize: none;
  background: inherit;
  color: #ABB0BF;
  font-size: 14px;
  font-weight: 400;
}

.talk:focus-visible {
  border: none;
  outline: 0
}

.send-button {
  width: 62px;
  height: 38px;
  background: #00CE9A;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
}
</style>
